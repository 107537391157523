import React from 'react';
import PrivateProvider from '@/components/providers/PrivateProvider';
import Layout from '@/layouts/LayoutDashboard';
import HeaderMetadata from '@/layouts/HeaderMetadata';
import Loadable from 'react-loadable';
import { LoadingPage } from '@/components/loading/loadingBase';

const ManagerLoadable = Loadable({
  loader: () => import('@/sections/Dashboard/Page/my-nfts'),
  loading: (props) => <LoadingPage isLoading={true} error={props.error} />,
});

const MyNFTPage: React.FC = () => {
  return (
    <React.Fragment>
      <HeaderMetadata title="My NFT" />
      <PrivateProvider>
        <Layout>
          <ManagerLoadable />
        </Layout>
      </PrivateProvider>
    </React.Fragment>
  );
};

export default MyNFTPage;
